@font-face {
  font-family: "oe";
  src: url("../fonts/OPTIEngraversOldEnglish.subset.woff2");
  font-display: swap;
}

.mast {
    font-family: 'oe', 'PT Serif', 'serif';
    font-size: 22px;
}

a:link { color: #0000CC; }
a:visited { color: #551A8B; }

nav {
    
    border-bottom: 2px solid #552222;
    margin-bottom: 15px;
    display: flex;
    height: 100%;
		   
}

BODY {
  max-width: 33em;
  margin: 30px auto;
  padding-left: 20px;
  padding-right: 20px;
  font-family: 'PT Serif', 'serif';
  font-size: 16px;
  background-color:  #FFFEFC;
  color: #333333
}


hr {
    border: none;
    height: 2px;
    /* Set the hr color */
    background-color: #552222; /* Modern Browsers */
}
